import React from 'react';
import { isSsr } from 'utils/isSsr';

export const OnlineCallButton = () => {
  const myZadarmaCallmeWidgetFn8521 = () => {
    (window as any).myZadarmaCallmeWidget8521 = new (window as any).ZadarmaCallmeWidget(
      'myZadarmaCallmeWidget8521',
    );
    (window as any).myZadarmaCallmeWidget8521.create(
      {
        widgetId:
          'jGVeeE1x7j6LSJ74tfm35Uy5ytPh56z2FD41pxcvnvyGd9RFP9aErLhmPd5mh3d2zzrs2bjTV6SnbUgjjyxA9aP1fvE4t6ms255349c3c9127046a874e1376a520618',
        sipId: '249176_0',
        domElement: 'myZadarmaCallmeWidget8521',
      },
      {
        shape: 'square',
        language: 'ru',
        width: '0',
        dtmf: false,
        font: "'Trebuchet MS','Helvetica CY',sans-serif",
        color_call: 'rgb(255, 255, 255)',
        color_bg_call: 'rgb(126, 211, 33)',
        color_border_call: 'rgb(191, 233, 144)',
        color_connection: 'rgb(255, 255, 255)',
        color_bg_connection: 'rgb(33, 211, 166)',
        color_border_connection: 'rgb(144, 233, 211)',
        color_calling: 'rgb(255, 255, 255)',
        color_border_calling: 'rgb(255, 218, 128)',
        color_bg_calling: 'rgb(255, 181, 0)',
        color_ended: 'rgb(255, 255, 255)',
        color_bg_ended: 'rgb(164,164,164)',
        color_border_ended: 'rgb(210, 210, 210)',
      },
    );
  };

  React.useEffect(() => {
    if (!isSsr) {
      const zadarmaScript = document.createElement('script');
      zadarmaScript.src =
        'https://my.zadarma.com/callmewidget/v2.0.8/loader.js';
      document.body.appendChild(zadarmaScript);
      zadarmaScript.async = true;
      zadarmaScript.addEventListener(
        'load',
        () => {
          const interval = setInterval(() => {
            if ((window as any).ZadarmaCallmeWidget) {
              myZadarmaCallmeWidgetFn8521();
              clearInterval(interval);
            }
          }, 200);
        },
        false,
      );
    }
  }, []);

  return (
    <>
      <div className="online-call-button" id="myZadarmaCallmeWidget8521" />
      <div id="zadarmaScripts" />
    </>
  );
};
